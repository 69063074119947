import React, {useContext, useState} from 'react';
import {Input, message} from 'antd';
import styled from 'styled-components';
import {Context} from '../../../src/AppContext';
import {Button as WidgetButton} from '../../../src/Widgets';
import StaticImage from '../../../src/Components/StaticImage';
const appConfig = require('../../../src/data.json');

const CustomerFormFields = [
  {
    name: 'name',
    placeholder: '您的姓名',
    required: true,
  },
  {
    name: 'phone',
    placeholder: '手機號碼',
    required: true,
  },
  {
    name: 'tel',
    placeholder: '市話',
    required: false,
  },
  {
    name: 'telExtension',
    placeholder: '分機號碼',
    required: false,
  },
  {
    name: 'email',
    placeholder: '電子信箱',
    required: true,
    validator: (value) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(value.toLowerCase());
    },
  },
];

const EmailFormFields = [
  {
    name: 'subject',
    placeholder: '信件主旨',
    required: true,
  },
  {
    name: 'content',
    placeholder: '信件內容',
    required: true,
  },
];

class ErrAboutPage extends Error {}

const Button = ({children, ...props}) => (
  <WidgetButton className="button hover-reverse-btn" {...props}>
    {children}
  </WidgetButton>
);

function ContactSection(props) {
  const [data, setData] = React.useState({
    name: '',
    phone: '',
    tel: '',
    telExtension: '',
    email: '',
    title: '',
    content: '',
  });

  const app = useContext(Context);
  const onFormSubmit = async () => {
    try {
      // basic validating form data
      let _blankFields = [];
      let _invalidFields = [];
      for (let f of [...CustomerFormFields, ...EmailFormFields]) {
        if (!data[f.name] && f.required) {
          _blankFields.push(f.placeholder);
        } else if (f.validator && !f.validator(data[f.name])) {
          _invalidFields.push(f.placeholder);
        }
      }
      if (_blankFields.length !== 0) {
        throw new ErrAboutPage(`請填入欄位：${_blankFields.join('、')}`);
      } else if (_invalidFields.length !== 0) {
        throw new ErrAboutPage(
          `請確認欄位：${_invalidFields.join('、')}的格式`,
        );
      }

      let formData = {
        subject: data.subject,
        message: data.content,
        name: data.name,
        phone: data.phone,
        local_phone: `${data.tel}#${data.telExtension}`,
        email: data.email,
      };

      await app.actions.contact(formData);

      message.success('聯絡表單已送出！');
    } catch (err) {
      if (err instanceof ErrAboutPage) {
        message.warning(err.message);
      } else {
        message.error('聯絡表單送出失敗！');
      }
    }
  };

  const onInputChange = (field) => (e) => {
    let value = e.target.value;
    setData((pre) => ({...pre, [field]: value}));
  };

  return (
    <Wrapper>
      <div className="title-container">
        <div className="title">聯絡我們</div>
        <div className="desp">
          {`如有任何問題或是想要詢價，歡迎填寫表單與我們聯絡！\n也可直接來信至： ${appConfig.information.email}`}
        </div>
      </div>

      <div className="form-wrapper">
        <div className="form" style={{alignItems: 'center'}}>
          <StaticImage
            filename="about-contact.png"
            extraStyle={{marginBottom: 41, width: 280, height: 276}}
          />
          <div className="contact-info">
            客服電話：{appConfig.information.tel}
          </div>
          <div className="contact-info">
            電子信箱：{appConfig.information.email}
          </div>
          <div className="contact-info">
            客服時間：{appConfig.information.service_time}
          </div>
          <div className="contact-info">
            粉絲專頁：{appConfig.socialMedia.facebook.name}
          </div>
          <div className="contact-info">
            Instagram ID：{appConfig.socialMedia.instagram.name}
          </div>
          <div className="contact-info">
            LINE ID：{appConfig.socialMedia.line.name}
          </div>
        </div>

        <div className="form">
          {CustomerFormFields.map((field, idx) => {
            if (field.name === 'tel') {
              let telExtension = CustomerFormFields[idx + 1];

              return (
                <div className="row">
                  <Input
                    key={idx}
                    value={data[field.name]}
                    onChange={onInputChange(field.name)}
                    placeholder={field.placeholder}
                  />
                  <Input
                    key={idx}
                    value={data[telExtension.name]}
                    onChange={onInputChange(telExtension.name)}
                    placeholder={telExtension.placeholder}
                    style={{marginLeft: 8, width: 180}}
                  />
                </div>
              );
            }

            if (field.name === 'telExtension') {
              return null;
            }

            return (
              <Input
                key={idx}
                value={data[field.name]}
                onChange={onInputChange(field.name)}
                placeholder={field.placeholder}
              />
            );
          })}

          {EmailFormFields.map((field, idx) => {
            if (field.name === 'content') {
              return (
                <Input.TextArea
                  key={idx}
                  // style={{ flex: 1 }}
                  value={data[field.name]}
                  placeholder={field.placeholder}
                  onChange={onInputChange(field.name)}
                />
              );
            }
            return (
              <Input
                key={idx}
                value={data[field.name]}
                onChange={onInputChange(field.name)}
                placeholder={field.placeholder}
              />
            );
          })}
          <div style={{marginTop: 40}}>
            <Button
              style={{maxWidth: 157, borderRadius: 0}}
              onClick={onFormSubmit}>
              確認送出
            </Button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f8f8f8;
  padding: 60px 20px;

  & > .title-container {
    margin-bottom: 30px;
    text-align: center;

    & > .title {
      margin: 0px auto 27px auto;
      margin-bottom: 27px;
      max-width: 170px;
      width: 100%;
      font-size: 30px;
      letter-spacing: 1px;
      color: ${appConfig.colors.third};
      border-bottom: 0.5px solid #bdbdbd;
      padding: 10px;
    }

    & > .desp {
      margin: 0 auto;
      max-width: 494px;
      width: 100%;
      white-space: pre-wrap;
      font-size: ${appConfig.fontSize.lg}px;
    }
  }

  & .form-wrapper {
    margin: 40px auto 35px auto;
    display: flex;

    max-width: ${appConfig.maxContentWidth}px;
    width: 100%;

    & .form {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      max-width: 640px;
      width: 100%;

      & input,
      & textarea {
        border: 1px solid #cccccc;
        padding: 8px 20px;
        color: #5a5a5a;
        border-radius: 2px;
        margin-bottom: 17px;
        font-size: 16px;

        &:focus {
          outline: none;
        }
      }

      & textarea {
        flex: 1;
        resize: none;
        min-height: 177px;
        margin-bottom: 0;
      }

      &:last-child {
        margin-left: 40px;
      }

      & > .contact-info {
        max-width: 340px;
        width: 100%;
        line-height: 1.78;
        font-size: ${appConfig.fontSize.lg}px;
      }
    }

    @media screen and (max-width: 600px) {
      flex-direction: column;

      & .form {
        margin-bottom: 30px;

        &:last-child {
          margin-left: 0px;
        }
        & input,
        & textarea {
          margin-bottom: 10px;
        }
      }
    }
  }

  & .error-hint {
    color: tomato;
    font-size: 12px;
    margin-top: 10px;
  }

  & .success-hint {
    color: #28a689;
    font-size: 12px;
    margin-top: 10px;
  }

  & .button {
    background-color: ${appConfig.colors.main};
    border-radius: 10px;
    width: 210px;
    font-weight: 500;

    &.hover-reverse-btn:hover {
      background-color: white;
      color: ${appConfig.colors.main};
    }
  }

  & .row {
    display: flex;
  }
`;

export default ContactSection;
