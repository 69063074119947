import React, {useContext, useState} from 'react';
import {Context} from '../../../src/AppContext';
import {Link, navigate} from 'gatsby';
import {Input, message} from 'antd';
import styled from 'styled-components';
import {Button as WidgetButton} from '../../../src/Widgets';
import StaticImage from '../../../src/Components/StaticImage';
import IntroSection from './IntroSection';
import ServiceSection from './ServiceSection';
import ContactSection from './ContactSection';
const appConfig = require('../../../src/data.json');

const MAX_WIDTH = appConfig.maxContentWidth;

class ErrAboutPage extends Error {}

export default function AboutPage(props) {
  return (
    <Wrapper>
      <img
        alt="about-banner"
        src={'/images/about-banner.jpg'}
        style={{width: '100%', height: 'auto'}}
        width="1370"
        height="611"
      />

      <div className="constraint">
        <IntroSection />
      </div>
      <div className="constraint">
        <ServiceSection />
      </div>
      <ContactSection />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #fff;
  flex: 1;

  & .constraint {
    max-width: ${MAX_WIDTH + 40}px;
    margin: 100px auto;
    padding: 0px 20px;
  }
`;
