import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {navigate} from 'gatsby';
import StaticImage from '../../../src/Components/StaticImage';
const appConfig = require('../../../src/data.json');

function IntroSection(props) {
  return (
    <Wrapper>
      <StaticImage
        filename="about-intro.png"
        extraStyle={{marginBottom: 42, width: 258, height: 89.27}}
      />
      <p>
        印印得致力於提倡生活美學，舉凡裝飾牆面的無框畫、床頭櫃小夜燈、餐桌杯墊..等，小小的巧思除了妝點生活情調，更能增加親友間的情感連結，送禮自用兩相宜。除此之外、展場活動、公關行銷宣傳贈品也是我們的一大主軸，歡迎有任何需求或想要開發商品皆可與我們聯絡。
      </p>
      <Button type="primary" onClick={() => navigate(`/products`)}>
        全部商品
      </Button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p {
    margin-bottom: 35px;
    max-width: 822px;
    width: 100%;
    line-height: 1.88;
    font-size: ${appConfig.fontSize.md}px;
    text-align: center;
  }
`;

export default IntroSection;
