import React from 'react';
import styled from 'styled-components';
const appConfig = require('../../../src/data.json');

const intro_items = [
  {
    icon: '/images/home-icon-01.png',
    title: '商品多元',
    subtitle: '符合你送禮的需求',
  },
  {
    icon: '/images/home-icon-02.png',
    title: '配合插畫家',
    subtitle: '協助繪製您理想的作品',
  },
  {
    icon: '/images/home-icon-03.png',
    title: '急件服務',
    subtitle: '臨時的需求、急件安排',
  },
  {
    icon: '/images/home-icon-04.png',
    title: '專人服務',
    subtitle: '大宗訂單轉以專案處理',
  },
];

function ServiceSection(props) {
  return (
    <Wrapper>
      {intro_items.map((item, idx) => (
        <ServiceItem index={idx} key={idx}>
          <img src={item.icon} width="130" height="130" alt="not found" />
          <div className="col">
            <p className="title" data-delighter>
              {item.title}
            </p>
            <p className="subtitle" data-delighter>
              {item.subtitle}
            </p>
          </div>
        </ServiceItem>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ServiceItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 25%;
  padding: 10px 0px;
  align-items: center;

  & .col {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 110px;

    & > .subtitle {
      text-align: center;
      color: #717071;
      font-size: 19px;
    }

    & p {
      margin-bottom: 0px;
    }

    & > .title {
      text-align: center;
      letter-spacing: 2px;
      font-size: 22px;
      color: #4e4e4e;
      margin-bottom: 5px;
    }
  }

  @media screen and (max-width: 600px) {
    flex-basis: 50%;

    & .col {
      margin-top: 30px;
      & > .subtitle {
        font-size: ${appConfig.fontSize.xs}px;
      }

      & > .title {
        font-size: ${appConfig.fontSize.sm}px;
      }
    }
  }

  ${(props) => props.css}
`;

export default ServiceSection;
